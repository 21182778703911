import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './components/About';
import Contact from './components/Contact';
import Error from './components/Error';
import Home from './components/Home';
import Projects from './components/Projects';
import SharedLayout from './components/SharedLayout';
import Services from './components/Services';
import * as ROUTES from './constants/routes'
import './sass/app.scss'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<SharedLayout />}>
          <Route index element={<Home />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route path={ROUTES.PROJECTS} element={<Projects />} />
          <Route path={ROUTES.SERVICES} element={<Services/>} />
          <Route path={ROUTES.CONTACT} element={<Contact />} />
          <Route path={ROUTES.ERROR} element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
