import React,{useState,useEffect} from 'react'
import '../sass/home.scss'
import bg1 from '../assets/bg-1.jpeg'
import bg2 from '../assets/bg-2.jpg'
import bg3 from '../assets/bg-3.jpg'
import office3 from '../assets/office-3.jpg'
import mcg from '../assets/mcg.svg'
import afrotsion from '../assets/afrotsion.svg'
import jplant from '../assets/jplant.svg'
import ewnra from '../assets/ewnra.svg'
import era from '../assets/era.svg'
import orlb from '../assets/orlb.png'
import crossland from '../assets/crossland.svg'
import aacra from '../assets/aacra.svg'
import artb from '../assets/artb.svg'
import unicone from '../assets/unicone.svg'
import { Link } from 'react-router-dom'
import * as ROUTES from '../constants/routes'
import { FiArrowRight, FiCheck } from 'react-icons/fi'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/react-splide/css/core';
import gradient_bg from '../assets/gradient-bg.svg'
import gradient_bgmob from '../assets/gradient-bgmob.svg'
import gy_dark from '../assets/gy-watermark-dark.svg'



import office_gy from '../assets/office-gy.jpeg'

const Home = () => {

  const [bgSlide,changeBgSlide] = useState(0);

  useEffect(()=>{
    const bgSlideInterval = setInterval(() => {
        changeBgSlide(bgSlide=>(bgSlide+1)%3)
    }, 6000);

    const allWithClass = Array.from(
      document.querySelectorAll('.animated')
    );

    allWithClass.forEach(el=>{
      if(el.getBoundingClientRect().top < window.innerHeight - 100 ){
        if(!el.classList.contains('show')){
          el.classList.add('show')
        }
        else{
        }
        // console.log(el.getBoundingClientRect().top)
        // console.log(window.innerHeight -100)
      }

      const slide = document.querySelector('.testimonials .slide')
    })



    window.addEventListener('scroll',()=>{
      const {innerHeight} = window;
      // console.log(innerHeight - 300)
      allWithClass.forEach(el=>{
        // console.log((el.getBoundingClientRect().top < (window.innerHeight - 100))+" top")
        // console.log(el.getBoundingClientRect().top+" top")
        if(el.getBoundingClientRect().top < innerHeight - 100 )
          // console.log(el)
          if(!el.classList.contains('show')){
            el.classList.add('show')
          }
      })
    })
    return ()=>clearInterval(bgSlideInterval)
  },[])

  const testimonials = [
    {
      img:mcg,
      desc:'This is to certify the good performance of G&Y Engineering Consult in undertaking and successfully completing design review services in line with the requirements of the contract agreement.',
      company:'MCG Construction PLC'
    },
    {
      img:era,
      desc:'We hereby confirm that the consultant, G&Y Engineering Consult PLC has been involved and successfully completed the design review, detail engineering design, concept design and supervision of various road projects in line with the contract requirements.',
      company:'Ethiopian Roads Administration (ERA)'
    },
    {
      img:ewnra,
      desc:'We have witnessed that G&Y Engineering Consult PLC executes its responsibilities with accepted standards and at its best professional skill.',
      company:'Ethio Wetlands and Natural Resources Association',
      position:'Executive Director'
    },
    {
      img:afrotsion,
      desc:'We are fully satisfied with the practical & economic viable design provided by the company.',
      company:'Afro-Tsion Construction',
      position:'Road Work Operations Deputy General Manager'
    },
    {
      img:jplant,
      desc:'We would highly recommend, without any reservation, that G&Y Engineering Consult PLC can handle project with high professional integrity and quality as they have exceptional capacity in carrying out the project.',
      company:'J-Plant Construction',
      position:'General Manager'
    },
    {
      img:orlb,
      desc:'We would like to notify that G&Y Engineering Consult PLC has duly/successfully undertaken responsibilities and duties as defined in the relevant terms and conditions of the service contract agreement.',
      company:'Oromia Roads and Logistics Bureau'
    },
    {
      img:crossland,
      desc:'G&Y Engineering Consult PLC has undertaken major duties and responsibilities, in regards to Detail Engineering Design services in accordance to employer\'s requirements and best practices of the civil engineering profession. We are fully satisfied with the practical and economical viable design provided by the company.',
      company:'Cross-Land Construction'
    },
    {
      img:unicone,
      desc:'United Consulting Engineers PLC certifies that the consulting company G&Y Engineering Consult has successfully completed the design review construction supervision and contract administration of roads projects.',
      company:'United Consulting Engineers PLC (UNICONE)'
    },
    {
      img:artb,
      desc:'We would like to testify that G&Y Engineering Consult PLC has rendered supervision services with due diligence and professional practice.',
      company:'Amhara Roads and Transport Bureau',
      position:'ROAD WORK OPERATIONS DEPUTY GENERAL MANAGER'
    },
  ]



  return (
    <div className='home'>
      <div className='hero d-flex flex-row align-items-center justify-content-between' >
          <div className={`hero-bg ${bgSlide===0?'active':''}`} style={{backgroundImage: `url(${office_gy})`}}>
          </div>
          <div className={`hero-bg ${bgSlide===1?'active':''}`} style={{backgroundImage: `url(${bg2})`}}>
          </div>
          <div className={`hero-bg ${bgSlide===2?'active':''}`} style={{backgroundImage: `url(${office3})`}}>
          </div>
        <div className='content-container d-flex flex-row justify-content-center justify-content-lg-between align-items-center'>
          <div className='slides-container d-flex flex-row col-10 col-lg-8'>
            <div className={`content-item ${bgSlide===0?'active':''}`}>
              <div className='content'>
                <h3>G&Y Engineering Consult</h3>
                <p>We are a multi-disciplinary Engineering Consultancy firm focused on urban and rural, engineering and infrastructure development projects</p>
                <Link to={ROUTES.CONTACT}>Get In Touch</Link>
              </div>
            </div>
            <div className={`content-item ${bgSlide===1?'active':''}`}>
              <div className='content'>
                <p>Leading Provider of Consultancy Services in Multiple Engineering Disciplines and Project Management</p>
                <Link  to={ROUTES.ABOUT}>More About Us</Link>
              </div>
            </div>

            <div className={`content-item ${bgSlide===2?'active':''}`}>
              <div className='content'>
                <p>Our technical capability along with our analytical experience allows us to develop a unique working model based on needs of the client and socioeconomic environments of each project</p>
                <Link  to={ROUTES.PROJECTS}>See Projects</Link>
              </div>
            </div>

          </div>
          <div className='block col-lg-1 d-none d-lg-block'></div>
        </div>
      </div>
      <div className='services col-10 m-auto'>

        <h2>The Services <br/>We Provide</h2>
        <div className='d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center'>

          <aside className='img-grid'>
                <img className='animated' src={bg1} alt="Two people working on an asphalt road with 2 trucks behind them" />
                <img className='animated' src={bg2} alt="A tractor pouring cement on a road that's under construction" />
                <img className='stretched animated' src={bg3} alt="Two roller tractors are pressing a fresh asphalt road" />
              </aside>
          <div className='content col-12 col-lg-6 p-0 d-flex flex-column justify-content-between align-items-start'>
            <p className=' animated'>
            Our Expertise in Design and Construction Supervision allows us to offer comprehensive work from commencement to delivery
            </p>
            <ul className=' animated'>
              <li><FiCheck size={20}/> <p>Innovation Engineering Solution</p></li>
              <li><FiCheck size={20}/> <p>Construction Supervision</p></li>
              <li><FiCheck size={20}/> <p>Road Detail Design</p></li>
            </ul>
            <Link to={ROUTES.SERVICES} className=' animated'>More<FiArrowRight size={20}/></Link>
          </div>
        </div>

        <div className='bg-watermark'>
          <img src={gy_dark} className='d-none d-lg-flex'/>
        </div>
      </div>
      <div className='testimonials'>
        <div className='bg-watermark'>
          <img src={gradient_bg} className='d-none d-md-flex'/>
          <img src={gradient_bgmob} className='d-flex d-md-none'/>
        </div>
        <h2>Testimonials</h2>
        <div className='slide-container'>
          <Splide options={{
                  type: "loop",
                  gap: "0px",
                  drag: "free",
                  arrows: false,
                  pagination: false,
                  autoScroll: {
                    pauseOnHover: true,
                    pauseOnFocus: true,
                    rewind: false,
                    speed: 1
                  }
            }}
            extensions={{ AutoScroll }}
            aria-label="React Splide Example">

              {testimonials.map((item,id)=>{
                return <SplideSlide>
                  <TestimonialItem item={item} key={id}/>
                </SplideSlide>
              })}
          </Splide>
          {/* <div className='slide'>
            {testimonials.map((item,id)=>{
              return <TestimonialItem item={item} key={id}/>
            })}
          </div> */}
        </div>
        <div className='col-12 d-flex flex-row align-items-end justify-content-end'>
          <Link to={ROUTES.ABOUT+'#partners'}>Clients And Partners<FiArrowRight size={20}/></Link>
        </div>
      </div>
      <div className='services col-10 m-auto'>
        <h2>Improving Traffic Safety In <br/> Addis Ababa</h2>
        <div className='d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center'>
          <div className='content col-12 col-lg-6 p-0 d-flex flex-column justify-content-between align-items-start'>
            <p className=' animated'>
              In Collaboration With:
            </p>
            <ul className=' animated'>
              <li><FiCheck size={20}/> <p>G And Y Engineering Consult PLC</p> </li>
              <li><FiCheck size={20}/> <p>InnoConnect</p></li>
              <li><FiCheck size={20}/> <p>United Nations Development Program</p></li>
              <li><FiCheck size={20}/> <p>Addis Ababa Traffic Management Agency</p></li>
              <li><FiCheck size={20}/> <p>Bloomberg Initiative for Global Road Safety</p></li>
              <li><FiCheck size={20}/> <p>Local Traffic Police</p></li>
            </ul>
            <p className=' animated'>
              To Find Out More Visit Our Site or InnoConnect's Website Below:
            </p>
            <div className="d-flex flex-column w-100 justify-content-between align-items-center">
              <a target="_blank" href="https://www.gandyengineering.com" className=' animated w-100'>G And Y<FiArrowRight size={20}/></a>
              <a target="_blank" href="https://innoconnect.net/our-project-helps-to-improve-traffic-safety-in-addis-ababa/" className=' animated w-100'>More<FiArrowRight size={20}/></a>
            </div>
          </div>
        </div>

        <div className='bg-watermark'>
          <img src={gy_dark} className='d-none d-lg-flex'/>
        </div>
      </div>
    </div>
  )
}

const TestimonialItem = ({item,key})=>{
  return <div className='testimonial-item col-11 col-md-auto d-flex flex-row align-items-start justify-content-between' key={key}>
  {/* <div className='company-logo'> */}
    <img src={item.img}/>
  {/* </div> */}
  <div className='desc d-flex flex-column'>
    <p>{item.desc}</p>
    <div className='credit'>
      <p>{` ${item.company}`}</p>
      {item.position&&<p className='info'>{`${item.position}`}</p>}
    </div>
  </div>
</div>
}

export default Home
